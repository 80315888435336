<template>
  <section class="other">
    <CoolLightBox
      @on-open="open"
      :items="fullImages"
      :index="index"
      @close="close"
    >
    </CoolLightBox>
    <div class="container">
      <div class="title">
        <h2>
          Other
          <div class="planets rotate">
            <svg
              width="185"
              height="117"
              viewBox="0 0 185 117"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="93.8995"
                cy="58.201"
                r="43.6603"
                transform="rotate(-90 93.8995 58.201)"
                fill="url(#paint0_linear_539_75)"
              />
              <circle
                cx="31.9977"
                cy="95.5814"
                r="6.2799"
                transform="rotate(-90 31.9977 95.5814)"
                fill="url(#paint1_linear_539_75)"
              />
              <circle
                cx="3.28947"
                cy="113.711"
                r="3.28947"
                transform="rotate(-90 3.28947 113.711)"
                fill="url(#paint2_linear_539_75)"
              />
              <circle
                cx="152.214"
                cy="20.8206"
                r="6.2799"
                transform="rotate(-90 152.214 20.8206)"
                fill="url(#paint3_linear_539_75)"
              />
              <circle
                cx="181.519"
                cy="3.28945"
                r="3.28947"
                transform="rotate(-90 181.519 3.28945)"
                fill="url(#paint4_linear_539_75)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_539_75"
                  x1="65.111"
                  y1="24.3643"
                  x2="109.453"
                  y2="96.813"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#0038FF" />
                  <stop offset="0.348958" stop-color="#143196" />
                  <stop offset="0.692708" stop-color="#000C37" />
                  <stop offset="1" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_539_75"
                  x1="27.8569"
                  y1="90.7144"
                  x2="34.2349"
                  y2="101.135"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#0038FF" />
                  <stop offset="0.348958" stop-color="#143196" />
                  <stop offset="0.692708" stop-color="#000C37" />
                  <stop offset="1" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_539_75"
                  x1="1.12048"
                  y1="111.161"
                  x2="4.46135"
                  y2="116.62"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#0038FF" />
                  <stop offset="0.348958" stop-color="#143196" />
                  <stop offset="0.692708" stop-color="#000C37" />
                  <stop offset="1" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_539_75"
                  x1="148.073"
                  y1="15.9537"
                  x2="154.451"
                  y2="26.3744"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#0038FF" />
                  <stop offset="0.348958" stop-color="#143196" />
                  <stop offset="0.692708" stop-color="#000C37" />
                  <stop offset="1" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_539_75"
                  x1="179.35"
                  y1="0.740105"
                  x2="182.691"
                  y2="6.19857"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#0038FF" />
                  <stop offset="0.348958" stop-color="#143196" />
                  <stop offset="0.692708" stop-color="#000C37" />
                  <stop offset="1" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </h2>
      </div>
      <div class="wrapper">
        <div
          class="img-wrapper"
          v-for="(image, imageIndex) in items"
          :key="`other-${imageIndex}`"
          @click="index = imageIndex"
        >
          <img loading="lazy" class="cool-border" :src="image.preview" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  name: "Other",
  components: {
    CoolLightBox,
  },
  data() {
    return {
      index: null,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    close() {
      this.index = null;
      document.documentElement.style.overflow = "auto";
    },
    open() {
      document.documentElement.style.overflow = "hidden";
    },
  },
  computed: {
    fullImages() {
      return this.items.map((item) => item.img);
    },
  },
};
</script>

<style>
.other .cool-lightbox .cool-lightbox__slide img {
  width: auto;
}
</style>
