import { render, staticRenderFns } from "./CardSkill.vue?vue&type=template&id=0e856f34&"
import script from "./CardSkill.vue?vue&type=script&lang=js&"
export * from "./CardSkill.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports